import {Box, Container, Link, Stack, Typography} from "@mui/material";
import SocialIcon from "../SocialIcon/SocialIcon";
import {TelegramIcon} from "../../Icon/TelegramIcon";
import {CicapLogoIcon} from "../../Icon/CicapLogoIcon";
import {YouTubeIcon} from "../../Icon/YouTubeIcon";
import {VkIcon} from "../../Icon/VkIcon";

const Footer = () => {
  return <>
    <Box sx={{background: "#000000", py: 4, color: "#949393"}}>
      <Container maxWidth="xl">
        <Stack direction={{xs: "column", md: "row"}} alignItems="center" justifyContent="space-between" spacing={4}>
          <CicapLogoIcon sx={{height: {xs: "40px", md: "70px"}, width: "auto", marginLeft: 0}} />
          <Box sx={{flex: 1, textAlign: "center"}}>
            <div>
              <Link href="https://cicap.ru/privacy-policy" target="_blank" color="inherit" fontSize="medium" sx={{textDecoration: "none"}}>Политика конфиденциальности</Link>
              <Typography component="span" fontSize="medium" mx={2}>/</Typography>
              <Link href="https://cicap.ru/offer-agreement" target="_blank" color="inherit" fontSize="medium" sx={{textDecoration: "none"}}>Публичная оферта</Link>
            </div>
            <div>
              <Link href="tel:89952665443" target="_blank" color="inherit" fontSize="medium" sx={{textDecoration: "none"}}>+7 995 266-54-43</Link>
              <Typography component="span" fontSize="medium" mx={2}>/</Typography>
              <Link href="tel:89933255443" target="_blank" color="inherit" fontSize="medium" sx={{textDecoration: "none"}}>+7 993 325-54-43</Link>
            </div>
          </Box>
          <Stack direction="row" alignItems="center" spacing={2}>
            <SocialIcon href="https://vk.com/cicap_official" icon={VkIcon} />
            <SocialIcon href="https://www.youtube.com/c/Boristrader/featured" icon={YouTubeIcon} />
            <SocialIcon href="https://t.me/Cicap_official_bot" icon={TelegramIcon} />
            <Typography
              fontSize="small"
              fontWeight="600"
              sx={{
                maxWidth: "14em",
                lineHeight: 1.1,
                display: {xs: "none", md: "block"},
              }}
            >
              Результаты технологии, лайв-стримы, аналитика
            </Typography>
          </Stack>
        </Stack>
      </Container>
    </Box>
  </>
}

export default Footer;
