import {Icon, InputBase, Stack} from "@mui/material";
import {InputFieldProps} from "./InputField.props";
import {ChangeEventHandler} from "react";

const InputField = ({icon, iconColor, placeholder, value, onChange}: InputFieldProps) => {
  const onChangeHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    onChange(e.target.value)
  }

  return <Stack
    direction="row"
    spacing={2}
    sx={{
      border: "2px solid #ddd",
      pl: 3,
      pr: 2,
      borderRadius: "3rem",
      pt: 2.25,
      pb: 1.75,
    }}
  >
    <Icon component={icon} sx={{fontSize: "1.75rem", color: iconColor, transform: "translateY(.15rem)"}} />
    <InputBase
      fullWidth
      placeholder={placeholder}
      value={value}
      onChange={onChangeHandler}
      sx={{fontSize: "1.15rem"}}
    />
  </Stack>
}

export default InputField;
