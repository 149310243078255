import {Icon, IconButton } from "@mui/material";
import {SocialIconProps} from "./SocialIcon.props";

const SocialIcon = ({icon, href}: SocialIconProps) => {
  return <IconButton
    href={href}
    target="_blank"
    sx={{
      background: "#b41c18",
      color: "#c1c1c1",
      width: "3rem",
      height: "3rem",
      "&:hover": {
        background: "#7d1310",
      }
    }}
  >
    <Icon component={icon} sx={{fontSize: "1.5rem"}} />
  </IconButton>;
}

export default SocialIcon;
